var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { flat: "", tile: "" },
            },
            [
              _c("Workflow", {
                attrs: {
                  width: "600",
                  height: "180",
                  steps: _vm.workflowSteps,
                  currentStep: _vm.workflowIndex,
                  labelWidth: 200,
                  lineWidth: 140,
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("TitleAndReturnComponent", {
                        attrs: {
                          title: "Référent / fonction digitale / établissement",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [_c("WarningEditComponent")],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xl: "11",
                            lg: "11",
                            md: "11",
                            sm: "11",
                            xs: "12",
                          },
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", active: _vm.loading },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("EstablishmentPanel", {
                    attrs: {
                      digitalName: _vm.digitalName,
                      metier: _vm.metier,
                      activities: _vm.activities,
                      status: _vm.status,
                    },
                  }),
                  _c("ReferentsPanel", {
                    attrs: {
                      entries: _vm.referents,
                      loading: _vm.loading,
                      editable: false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }