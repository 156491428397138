<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent
              title="Référent / fonction digitale / établissement"
            />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="11" lg="11" md="11" sm="11" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <EstablishmentPanel
            :digitalName="digitalName"
            :metier="metier"
            :activities="activities"
            :status="status"
          />

          <ReferentsPanel
            :entries="referents"
            :loading="loading"
            :editable="false"
          />
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import ReferentsPanel from "./ReferentsPanel.vue";
import EstablishmentPanel from "./EstablishmentPanel.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import EstablishmentsService from "@/service/etablishment/etablishment_service.js";
import ReferentFunctionService from "@/service/etablishment/referent_function_service.js";
import { FonctionService } from "@/service/sfr/function_service.js";
import ServicesService from "@/service/sfr/services_service.js";
import { UsersService } from "@/service/user/dg_user_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "DetailReferentFunction",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    ReferentsPanel,
    EstablishmentPanel,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /**le service d'accès aux référents / fonctions. */
      serviceReferents: null,

      /**le service d'accès aux services DOCTEGESTIO. */
      serviceServices: null,

      /**le service d'accès aux fonctions digitales. */
      serviceFunctions: null,

      /**le service d'accès aux collaborateurs. */
      serviceUsers: null,

      /**le service d'accès aux activités. */
      serviceActivities: null,

      /**le service d'accès aux métiers. */
      serviceMetiers: null,

      /** l'identifiant à éditer */
      establishmentId: null,

      /**La donnée originale (les référents par fonction*/
      source: null,

      /**le nom digital */
      digitalName: null,

      /**le métier de l'établissement */
      metier: null,

      /**les activités de l'établissement */
      activities: [],

      /**le statut */
      status: null,

      /**les référents de fonction */
      referents: [],

      /**la liste des services du groupe */
      services: [],

      /**la liste des fonctions du groupe */
      functions: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.referents = [];
        this.digitalName = "-";
        this.metier = "-";
        this.activities = [];
        this.status = "-";

        //chargement de l'établissement
        let establishment = await this.service.getById(this.establishmentId);

        //mail du 21/01/2021 ed SR: afficher le nom digital, le métier, les activités et le statut des établissements.

        this.digitalName = establishment.digitalName;

        //on réupère le métier de l'établissement
        if (establishment.metierId) {
          let metier = await this.serviceMetiers.getById(
            establishment.metierId
          );
          this.metier = metier.name;
        } else {
          this.metier = "-";
        }

        //les activités de l'établissement
        this.activities = await this.serviceActivities
          .getActivities()
          .then((activities) =>
            activities.filter((a) => establishment.activityIds.includes(a.id))
          );

        //le statut de l'établissement
        this.status = establishment.status;

        this.init();

        let list =
          await this.serviceReferents.getReferentFunctionByEstablishment(
            this.establishmentId
          );
          // console.log('referentsFunction ' + JSON.stringify(list, null, 4));

        this.source = JSON.parse(JSON.stringify(list));

        //on récupère les services
        this.services = await this.serviceServices.getAllServices();

        //on récupère les fonctions
        this.functions = await this.serviceFunctions.getAll();

        let referentsUuids = [];
        let id = 0;

        for (let entry of list) {
          //on recherche la définition de la fonction correspondante
          let func = this.functions.find((f) => f.id === entry.function);

          //on recherche la définition du service associé
          let srv = this.services.find((s) => s.id === func.serviceId);
          // console.log(
          //   "entry.referentsUuids: " + JSON.stringify(entry.referentsUuids)
          // );
          for (let userUuid of entry.referentsUuid) {
            //on recherche le collaborateur
            let user = await this.serviceUsers.getByUuid(userUuid);

            let referent = {
              function: func.name,
              service: srv.name,
              referent: user.gsuiteaccount.email,
              referentUuid: user.uuid,
              functionId: entry.function,
            };

            referent.id = id;

            referentsUuids.push(referent);

            id++;

            // console.log(JSON.stringify(user, null, 4));
          }
        }

        this.referents.push(...referentsUuids);

        // console.log(JSON.stringify(establishment, null, 4));
        // console.log(JSON.stringify(referents, null, 4));
        // console.log(JSON.stringify(this.source, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    init() {},
    onShowAddDialog() {
      this.showPopup = true;
    },
  },
  computed: {},
  mounted() {
    this.service = new EstablishmentsService(this.$api);
    this.serviceReferents = new ReferentFunctionService(
      this.$api.getReferentFunctionApi()
    );

    this.serviceFunctions = new FonctionService(this.$api.getFunctionApi());
    this.serviceServices = new ServicesService(this.$api);
    this.serviceUsers = new UsersService(this.$api.getUsersApi());
    this.serviceActivities = new ActivityService(this.$api);
    this.serviceMetiers = new MetierService(this.$api.getMetierApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.establishmentId = Number(this.$route.params.id);
    }

    this.load();
  },
};
</script>

<style></style>
